.generateMCQ {
  background-color: var(--body-color);
  height: 100%;
  padding: 1rem;
  font-size: 1.6rem;
  margin-top: 6.7rem;
}

.generateMCQ_top {
  display: flex;
}

.generateMCQ_top_left {
  flex: 0.2;
  background-color: var(--white-color);
  padding: 1rem;
}

.generateMCQ_top_right {
  flex: 0.8;
  margin-left: 1rem;
  background-color: var(--white-color);
}

.generateMCQ_top_left_header {
  font-weight: 600;
}

.generateMCQ_label {
  margin-bottom: 1rem;
}

.generateMCQ_top_left_items {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.generateMCQ_top_left_bottom {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.generateMCQ_top_left_bottom_button {
  background-color: var(--primary-color);
  color: var(--white-color);
  border: none;
  height: 3.6rem;
  width: 10rem;
  font-weight: 600;
}

.generateMCQ_top_left_bottom_button:hover {
  box-shadow: inset 0 0 10rem 10rem rgba(0, 0, 0, 0.1);
}

.generateMCQ_top_right {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  padding: 2rem;
}

.generateMCQ_top_right_main {
  display: flex;
  width: 80%;
  justify-content: space-between;
  margin-top: 2rem;
}

.generateMCQ_checkbox {
  margin-right: 1rem;
}

.generateMCQ_top_right_main_left_content {
  display: flex;
  align-items: center;
}

.generateMCQ_top_right_main_right_content,
.generateMCQ_top_right_main_left_content {
  height: 4rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
}

.generateMCQ_top_right_main_right_content {
  display: flex;
  justify-content: center;
}

.generateMCQ_input {
  width: 60%;
  text-align: center;
  height: 3.5rem;
  border: none;
  background-color: var(--body-color);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* input[type="number"] {
  -moz-appearance: textfield;
} */

input {
  outline: none;
  width: 100%;
  padding: 6px 5px;
}

textarea {
  resize: none;
  outline: none;
  width: 100%;
  padding: 6px 5px;
  height: 150px;
}

.inputBlock {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-content: flex-start;
  gap: 15px;
  margin: 12px;
  padding: 0 20px;
}

input:hover,
input:focus,
textarea:focus,
textarea:hover,
textarea:active {
  padding-left: 10px
}

.generateMCQ_bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  margin-bottom: 3rem;
}

.generateMCQ_bottom_items {
  margin-right: 5rem;
}

.generateMCQ_bottom_items_button {
  background-color: var(--primary-color);
  color: var(--white-color);
  border: none;
  width: 15rem;
  height: 4rem;
  font-weight: 600;
}

.generateMCQ_bottom_items_span {
  font-weight: 600;
}

.generateMCQ_top_right_bottom {
  margin-top: 3rem;
}

.generateMCQ_top_right_bottom_button {
  background-color: var(--primary-color);
  border: none;
  color: var(--white-color);
  width: 10rem;
  height: 3.5rem;
  font-weight: bold;
}

.generateMCQ_top_right_bottom_button:hover {
  box-shadow: inset 0 0 10rem 10rem rgba(0, 0, 0, -0.1);
}