.signin {
    height: 100vh;
    background-color: var(--white-color);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;
}

.signin_div {
    background-color: white;
    width: 50rem;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    padding: 1rem;
}

.project_logo {
    height: 20rem;
    /* width: 20rem; */
}

.signin_div_mid p {
    font-size: 2.5rem;
    font-weight: bold;
    letter-spacing: 0.2rem;
    margin-bottom: 3rem;
}

.signin_div_form {
    width: 100%;
}

.signin_div_form_item_input {
    width: 100%;
    /* margin-bottom: 2rem; */
    height: 5rem;
    padding: 0px 1rem;
    border: 2px solid lightgray;
    border-radius: 5px;
}

.signin_div_form_item_input:focus {
    outline: none !important;
    border: 2px solid var(--primary-color);
}

.signin_div_button {
    width: 100%;
}

.signin_div_button button {
    background-color: var(--primary-color);
    border: none;
    color: var(--white-color);
    width: 100%;
    height: 4rem;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    cursor: pointer;
}

.signin_div_button button:hover {
    box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.1);
}

.signin_div_bottom {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.signin_div_bottom_left a {
    color: var(--primary-color);
    font-size: 1.4rem;
    text-decoration: none;
}

.signin_div_bottom_right a {
    color: var(--primary-color);
    font-size: 1.4rem;
    text-decoration: none;
}

.signin_div_bottom {
    margin-bottom: 5rem;
}

.signin_div_form_item:first-child {
    display: flex;
    align-items: start;
}

.signUp_show_password_div {
    display: flex;
    justify-content: end;
    align-items: center;
    margin: 0 0 0.8rem 0;
}

.signUp_show_password_checkbox {
    width: auto;
    margin-right: 0.5rem;
}

.margin-right {
    margin-right: 1rem;
}

.signin_div_copyright p {
    color: #a0a0a0;
}