.createSyllabus {
  /* height: 90vh; */
  background-color: var(--background-color);
  font-size: 1.6rem;
}

.createSyllabus_div {
  margin: 8rem 10rem;
  padding: 3rem 5rem;
  background-color: var(--white-color);
  box-shadow: 1px -1px 9px 0px rgba(212, 211, 211, 0.75);
  -webkit-box-shadow: 1px -1px 9px 0px rgba(212, 211, 211, 0.75);
  -moz-box-shadow: 1px -1px 9px 0px rgba(212, 211, 211, 0.75);
  border-radius: 0.5rem;
}

.createSyllabus_div_input {
  border: 1px solid var(--input-border);
  border-radius: 0.3rem;
  height: 4rem;
  width: 100%;
  margin-bottom: 2rem;
  padding: 1rem;
}

.createSyllabus_div_input:hover,
.createSyllabus_div_input:active,
.createSyllabus_div_input:focus {
  border: 1px solid var(--input-border);
}

.createSyllabus_div_label {
  margin-bottom: 0.8rem;
  display: block;
}

.createSyllabus_div_textarea {
  border: 1px solid var(--input-border);
  border-radius: 0.3rem;
  width: 100%;
  padding: 1rem;
  height: 10rem;
  margin-bottom: 2rem;
}

.createSyllabus_div_textarea:hover,
.createSyllabus_div_textarea:active,
.createSyllabus_div_textarea:focus {
  border: 1px solid var(--input-border);
}

.createSyllabus_div_file,
.createSyllabus_div_file:hover,
.createSyllabus_div_file:active,
.createSyllabus_div_file:focus {
  border: none;
  padding: 0;
  display: none;
}

.createSyllabus_div_upload {
  display: flex;
  width: 100%;
}

.createSyllabus_div_upload_option {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  width: 80%;
  margin-bottom: 2rem;
}

.createSyllabus_div_uploadButton {
  background-color: #e1e1e1;
  height: 3.2rem;
  width: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2rem;
  border-radius: 3px;
}

.createSyllabus_div_bottom {
  /* background-color: red; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
}

.createSyllabus_div_button_confirm,
.createSyllabus_div_button_cancel {
  margin-right: 3rem;
  background-color: var(--primary-color);
  border: none;
  color: var(--white-color);
  padding: 0.6rem 1.4rem;
  border-radius: 0.3rem;
}

.createSyllabus_div_button_cancel {
  background-color: rgb(159, 159, 159);
}