li {
  list-style: none;
}

a {
  text-decoration: none;
  text-transform: capitalize;
  color: var(--black-color);
}

a:hover {
  color: var(--primary-color);
}

.nav {
  display: flex;
  font-size: 1.6rem;
  align-items: center;
  justify-content: space-between;
  padding: 0 5rem;
  height: 7rem;
  background-color: var(--white-color);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000000;
  box-shadow: 2px 2px 14px 0px rgba(196, 196, 196, 0.75);
  -webkit-box-shadow: 2px 2px 14px 0px rgba(196, 196, 196, 0.75);
  -moz-box-shadow: 2px 2px 14px 0px rgba(196, 196, 196, 0.75);
}

.nav__brand {
  text-transform: capitalize;
}

.active {
  color: var(--primary-color);
}

.nav__menu__mid {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  gap: 3rem;
}

.margin_right {
  margin-right: 2rem;
}

.nav__menu__right {
  display: flex;
}

.nav__toggler {
  display: none;
}

.flex_item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav_icon {
  margin-right: 0.5rem;
}

.login_gap {
  margin-right: 3rem;
}

.nav__toggler div {
  z-index: 100000;
  width: 2.5rem;
  height: 0.2rem;
  margin: 0.4rem;
  background-color: black;
  transition: 0.2s ease-in;
  cursor: pointer;
}

@media screen and (max-width: 1080px) {
  .nav__toggler {
    display: block;
    cursor: pointer;
  }

  .nav__menu__right,
  .nav__menu__mid {
    display: none;
  }
}

/* Active Class */
.nav__active {
  transform: translateX(0%);
}

/* Toggle Icon Animation */

.toggle .line1 {
  transform: rotate(-45deg) translate(-4px, 5px);
  background-color: white;
}

.toggle .line2 {
  opacity: 0;
}

.toggle .line3 {
  background-color: white;
  transform: rotate(45deg) translate(-4px, -5px);
}