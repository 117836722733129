.practiceSetComponent {
  margin-top: 8rem;
  font-size: 1.6rem;
  padding: 3rem;
}
.practiceSetComponent_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.practiceSetComponent_link {
  font-weight: bold;
  text-decoration: none;
  padding: 0.3rem 0.6rem;
}
.practiceSetComponent_link:hover {
  background: #bdd2f2;
  border-radius: 5px;
}
.carousel {
  text-align: center;
  padding-bottom: 2rem;
}

/* horizontal Scroll */
.practiceSetComponent_mid {
  display: grid;
  gap: 4rem;
  grid-auto-flow: column;
  grid-auto-columns: 25%;
  overflow-x: auto;
  overscroll-behavior-inline: contain;
  padding: 0 2rem 5rem;
}

.spans-inline {
  scroll-snap-type: inline mandatory;
  scroll-padding-inline: 3rem;
}

.snaps-inline > * {
  scroll-snap-align: start;
}
/* horizontal Scroll */
.practiceSetComponent_mid_items {
  margin-right: 1rem;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}
.practiceSetComponent_top_header {
  font-size: 2.5rem;
}

/* scroll bar */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #878787;
}
/* scroll bar */
