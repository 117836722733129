.home {
  height: 90vh;
  background-color: var(--primaryLight-color);
  display: flex;
  justify-content: center;
  font-size: 1.6rem;
  align-items: center;
}

.home_div {
  width: 85%;
  display: flex;
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
}

.home_div_left {
  flex: 1;
  margin-right: 13rem;
}

.home_div_right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home_div_right h2 {
  font-weight: bold;
}

.home_div_right_bottom {
  margin-top: 2rem;
  display: flex;
}

.home_div_right_bottom_left {
  background-color: var(--primary-color);
  color: var(--white-color);
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15rem;
  border-radius: 0.5rem;
  cursor: pointer;
  margin-right: 2rem;
}

.home_div_right_bottom_right {
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18rem;
  border: none;
  color: black;
  font-weight: bold;
  border-radius: 0.5rem;
  cursor: pointer;
  margin-right: 2rem;
  transition: 0.4s ease;
}

.home_icon {
  font-size: 3rem !important;
}

.home_div_right_bottom_right:hover {
  background-color: var(--primary-color);
  color: var(--white-color);
}

@media (max-width: 1155px) {
  .home_div {
    width: 100%;
  }

  .home_div_left {
    margin-right: 10rem;
  }
}

@media (max-width: 1060px) {
  .home_div {
    width: 100%;
  }

  .home_div_left {
    /* margin-right: 6rem;
    margin-bottom: 5rem; */
    margin: 0px;
  }
}

@media (max-width: 1010px) {
  .home_div {
    width: 100%;
  }
}

@media (max-width: 937px) {
  .home_div {
    flex-direction: column;
  }

  .home {
    height: 100%;
    /* padding: 4rem; */
  }

  .home_div_left {
    margin: 0px;
    /* margin-top: 7rem;
    margin-right: 0; */
  }

  .home_div_right {
    margin: 3.5rem 2rem;
  }

  .home_div_right_bottom_left {
    width: 40%;
    margin-right: 0rem;
  }

  .home_div_right_bottom_right {
    width: 58%;
  }
}

@media (max-width: 658px) {
  .navbar {
    font-size: 1.5rem;
    padding: 0 2rem;
  }
}
