.pagination-for-small-device {
    display: none;
}

.practiceExam_button_exams_grid {
    display: grid;
    grid-gap: 16px;
}

@media (min-width: 768px) {
    .practiceExam_button_exams_grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 960px) {
    .practiceExam_button_exams_grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 904px) {
    .pagination-for-large-device {
        display: none;
    }

    .pagination-for-small-device {
        display: block;
    }
}