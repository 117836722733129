.filter_div {
    background-color: gainsboro;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: large;
}

.filter_tags_div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
}

.filter_tag_btn {
    background: var(--primary-color);
    color: white;
    padding: 0.8rem 1.4rem;
    margin: 1rem;
    min-width: 3rem;
    border-radius: 0.8rem;
    cursor: pointer;
}

.filter_tag_btn:hover,
.filter_button:hover {
    transform: scale(1.03);
}

.filter_tag_selected {
    background-color: green;
}

.filter_btns_div {
    display: flex;
}

.filter_button {
    color: white;
    padding: 0.6rem 1.2rem;
    margin: 0 1rem;
    min-width: 7.9rem;
    border-radius: 0.8rem;
    cursor: pointer;
    text-align: center;
}

.filter_apply_btn {
    background-color: green;
}

.filter_cancel_btn {
    background-color: red;
}