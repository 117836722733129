.liveExam {
  padding: 1rem;
  font-size: 1.6rem;
  background-color: var(--body-color);
  margin-top: 7rem;
}

.liveExam_body {
  display: flex;
}

.liveExam_body_right_header {
  text-align: center;
  margin: 2rem 0;
}

.exam_place_popup_sure_btn {
  background-color: green;
  border: none;
  color: var(--white-color);
  padding: 5px 15px;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.exam_place_popup_no_btn {
  background-color: red;
  border: none;
  color: var(--white-color);
  padding: 5px 15px;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.liveExam_body_left {
  flex: 0.7;
  margin-right: 1rem;
  background-color: var(--white-color);
  /* background-color: red; */
  padding: 3.9rem 0;
}

.liveExam_body_left_top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.liveExam_body_left_top_left {
  display: flex;
}

.green {
  color: var(--submit-color);
}

.red {
  color: var(--cancel-color);
}

.golden {
  color: var(--golden-color);
}

.liveExam_body_left_top_left_content {
  margin-left: 2rem;
}

.liveExam_body_left_top_right {
  margin-right: 2rem;
}

.liveExam_body_right {
  flex: 0.3;
  background-color: var(--white-color);
}

.liveExam_body_left_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.liveExam_body_left_mid {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
}

.liveExam_body_left_bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.liveExam_body_left_bottom_mid {
  margin: 0 5rem;

  height: 4rem;
  width: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: goldenrod;
  border: none;
  color: var(--white-color);
}

.liveExam_body_left_bottom_left,
.liveExam_body_left_bottom_right {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  width: 15rem;
}

.liveExam_body_left_bottom_left:hover,
.liveExam_body_left_bottom_right:hover,
.liveExam_body_left_bottom_mid:hover {
  box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.liveExam_body_right_grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
  margin: 1rem;
  text-align: center;
}

.liveExam_body_right_item {
  background-color: lightgray;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.liveExam_body_right_item:hover {
  cursor: pointer;
  box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.1);
}

.liveExam_body_right_button {
  margin-top: 2rem;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.liveExam_body_right_button_submit,
.liveExam_body_right_button_cancel {
  height: 3.5rem;
  width: 9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
}

.liveExam_body_right_button_submit {
  background-color: var(--submit-color);
  margin-right: 3rem;
}

.liveExam_body_right_button_cancel {
  background-color: var(--cancel-color);
}

.liveExam_body_right_button_submit:hover,
.liveExam_body_right_button_cancel:hover {
  box-shadow: inset 0 0 200px 200px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.exam_ended_page {
  margin-top: 7rem;
  height: calc(90vh - 7rem);
  width: 98vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ended_info {
  font-size: 2.8rem;
  text-align: center;
}

@media screen and (max-width: 880px) {

  .liveExam_body_left_bottom_left,
  .liveExam_body_left_bottom_right {
    height: 4rem;
    width: 10rem;
  }
}

@media screen and (max-width: 822px) {
  .liveExam_body_right_grid {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0.5rem;
  }

  .liveExam_body_right_button_submit {
    margin-right: 2rem;
  }

  .liveExam_body_right_button_submit,
  .liveExam_body_right_button_cancel {
    height: 3.5rem;
    width: 7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white-color);
  }
}

@media screen and (max-width: 755px) {
  .liveExam_body {
    flex-direction: column;
  }

  .liveExam_body_right {
    margin-top: 1rem;
    padding: 2rem;
  }

  .liveExam_body_right_grid {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;
  }

  .liveExam_body_left {
    margin-right: 0;
    /* background-color: red; */
    height: fit-content;
  }

  .liveExam_body_left_bottom {
    margin-top: 2rem;
  }

  .liveExam_body_left_bottom_mid {
    margin: 0 3rem;
  }
}

@media screen and (max-width: 499px) {
  .liveExam_body_left_top {
    flex-direction: column;
  }

  .liveExam_body_left_top_right {
    margin-right: 0;
    margin-top: 0.5rem;
  }
}

@media screen and (max-width: 387px) {
  .liveExam_body_left_bottom_mid {
    margin: 0 1rem;
  }
}