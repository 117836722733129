.footer {
  background-color: var(--white-color);
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 2rem;
  font-size: 1.4rem;
  box-shadow: 2px -2px 2px -2px rgba(0, 0, 0, 0.2);
}

.footer_top {
  display: grid;
  grid-template-columns: 40% 20% 20% 20%;
}

.top_left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer_top_div_heading {
  font-size: 1.6rem;
  font-weight: bold;
  letter-spacing: 0.01rem;
  margin-bottom: 1rem;
}

.footer_top_div_links {
  margin-bottom: 0.8rem;
}

.footer_top_div_links a {
  text-decoration: none;
  color: #393939;
}

.footer_top_div_content {
  margin-bottom: 0.8rem;
  color: #393939;
  text-align: left;
}

.footer_top_div_icons {
  width: 25%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
}

.footer_top_div_icons_media {
  color: grey !important;
  font-size: 2rem !important;
  cursor: pointer;
}

.footer_top_div_icons_media:first-child:hover {
  color: #3b5998 !important;
}

.footer_top_div_icons_media:nth-child(2):hover {
  color: #8a3ab9 !important;
}

.footer_top_div_icons_media:nth-child(3):hover {
  color: #00acee !important;
}

.footer_top_div_icons_media:nth-child(4):hover {
  color: #ff0000 !important;
}

.footer_bottom {
  margin-top: 2rem;
  border-top: 1px solid lightgray;
  padding-top: 2rem;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 923px) {
  .footer_top_div_icons {
    width: 30%;
  }
}

@media (max-width: 892px) {
  .footer_top {
    padding: 0 10rem;
  }

  .footer_top_div_icons {
    width: 30%;
  }

  .footer_top {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 5rem;
  }

  .top_left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}

@media (max-width: 550px) {
  .footer_top {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 5rem;
    padding: 0 0;
  }

  .top_left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footer_top_div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

@media (max-width: 438px) {
  .footer_bottom {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footer_bottom_left {
    margin-bottom: 2rem;
  }

  .footer_bottom_right {
    margin-bottom: 2rem;
  }
}