.hero_container {
  background-image: url("../../assets/header_pic.png");
  height: calc(100vh - 7.5rem);
  background-size: cover;
  background-position: bottom;
  display: flex;
  font-size: 1.6rem;
  margin-top: 7rem;
}

.hero_container_left {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.hero_container_left_box {
  /* background-color: red; */
  width: 70%;
}

.hero_container_left_header {
  font-size: 4rem;
  font-weight: bold;
}

.hero_container_left_box_bottom {
  margin-top: 2rem;
  display: flex;
}

.hero_container_left_box_bottom_button {
  background-color: var(--primary-color);
  color: var(--white-color);
  text-decoration: none;
  padding: 1.3rem 1.8rem;
  font-size: 1.8rem;
  font-weight: 600;
  letter-spacing: 0.01rem;
  border-radius: 25px;
  margin-right: 2rem;
  margin-bottom: 1rem;
}

.hero_container_left_box_bottom_button:hover {
  text-decoration: none;
  color: var(--white-color);
}

.hero_container_left_box_bottom_button_two_icon {
  font-size: 4rem !important;
  color: var(--white-color);
  margin-right: 0.5rem;
}

.hero_container_left_box_bottom_button_two {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
  font-size: 1.8rem;
  font-weight: 600;
  letter-spacing: 0.01rem;
  text-decoration: none;
  color: var(--black-color);
}

.hero_container_left_box_bottom_button_two:hover {
  text-decoration: none;
  color: var(--black-color);
}

.text_color {
  color: var(--text-color);
}

.hero_container_left_text {
  margin: 2rem 0;
}

@media (max-width: 1240px) {
  .hero_container_left_box_bottom {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .hero_container_left_header {
    font-size: 3rem;
  }
}

@media (max-width: 1095px) {
  .hero_container_left_header {
    font-size: 2.5rem;
  }

  .hero_container_left_text {
    font-size: 1.4rem;
  }
}

@media (max-width: 930px) {
  .hero_container {
    height: fit-content;
    padding-bottom: 3rem;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: start;
  }

  .hero_container_left {
    margin: 0rem;
    margin-top: 5rem;
    width: 100vw;
    display: flex;
    justify-content: start;
    padding: 3rem 0rem;
  }

  .hero_container_left_box_bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .hero_container_left_header {
    font-size: 4.5rem;
  }

  .hero_container_left_text {
    font-size: 2.4rem;
  }

  .hero_container_left_box_bottom_button,
  .hero_container_left_box_bottom_button_two {
    font-size: x-large;
  }
}

@media (max-width: 810px) {
}

@media (max-width: 620px) {
  .hero_container_left_box_bottom_button,
  .hero_container_left_box_bottom_button_two {
    font-size: medium;
  }
}
@media (max-width: 490px) {
  .hero_container_left_header {
    font-size: 2.9rem;
  }

  .hero_container_left_text {
    font-size: 1.8rem;
  }
}

@media (max-width: 460px) {
  .hero_container_left_header {
    font-size: 2.9rem;
  }

  .hero_container_left_text {
    font-size: 1.6rem;
  }

  .hero_container_left_box_bottom_button_two_icon {
    font-size: 2.5rem !important;
  }

  .hero_container_left_box_bottom_button_two {
    font-size: 1.2rem;
  }
}
@media (max-width: 420px) {
  .hero_container_left_box {
    /* background-color: red; */
    width: 80%;
  }
}

@media (max-width: 380px) {
  .hero_container_left_box {
    /* background-color: red; */
    width: 90%;
  }
}
