.overall_result_page {
    margin-top: 6rem;
    padding: 2rem 4rem;
    /* height: 90vh; */
}

.overall_top_part {
    display: flex;
    justify-content: space-around;
}

.overall_top_left {
    padding: 2rem 0rem;
    font-size: x-large;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 30vw;
}

.overall_vr_line_top {
    width: 0.38px;
    background-color: gainsboro;
}

.overall_hr_line_top {
    height: 0.38px;
    background-color: gainsboro;
    display: none;
}

.overall_top_right {
    padding: 2rem 0rem;
    font-size: large;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.overall_fieldName {
    font-weight: 500;
}

.overall_result_show_table {
    padding: 1rem;
    text-align: center;
}

.overall_hr_line {
    height: 0.18px;
    background-color: gainsboro;
    margin: 2rem 0;
}

@media (max-width: 820px) {
    .overall_top_left {
        max-width: 40vw;
    }
}

@media (max-width: 662px) {
    .overall_top_left {
        max-width: none;
    }

    .overall_top_part {
        flex-direction: column;
    }

    .overall_vr_line_top {
        display: none;
    }

    .overall_hr_line_top {
        display: block;
    }
}

@media (max-width: 360px) {
    .overall_top_left {
        font-size: large;
    }

    .overall_top_right {
        font-size: medium;
    }
}