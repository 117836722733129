.newVacancy {
  background-color: var(--background-color);
  font-size: 1.6rem;
  height: 100%;
}

.newVacancy_div {
  margin: 8rem 10rem;
  padding: 3rem 5rem;
  background-color: var(--white-color);
  box-shadow: 1px -1px 9px 0px rgba(212, 211, 211, 0.75);
  -webkit-box-shadow: 1px -1px 9px 0px rgba(212, 211, 211, 0.75);
  -moz-box-shadow: 1px -1px 9px 0px rgba(212, 211, 211, 0.75);
  border-radius: 0.5rem;
  /* margin-bottom: 10rem; */
}

.newVacancy_div_top {
  display: flex;
  margin-bottom: 2rem;
}

.newVacancy_div_top_left {
  flex: 0.85;
  margin-right: 2rem;
}

.newVacancy_div_top_right {
  flex: 0.15;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
}

.newVacancy_div_top_right_file {
  display: none;
}

.newVacancy_label {
  margin-bottom: 0.5rem;
  display: block;
}

.newVacancy_input {
  border: 1px solid var(--input-border);
  width: 100%;
  padding: 1rem;
  height: 4rem;
}

.newVacancy_input:active,
.newVacancy_input:focus,
.newVacancy_input:hover {
  border: 1px solid var(--input-border);
}

.newVacancy_chooseFile {
  background-color: #e1e1e1;
  color: black;
  border-radius: 3px;
  width: 100%;
  height: 3.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.newVacancy_description {
  border: 1px solid var(--input-border);
  width: 100%;
  height: 10rem;
  border-radius: 3px;
  padding: 1rem;
  margin-bottom: 2rem;
}

.newVacancy_paragraph_input {
  width: 100%;
  height: 10rem;
  border-radius: 3px;
  padding: 1rem;
}

.newVacancy_description:active,
.newVacancy_description:focus,
.newVacancy_description:hover {
  border: 1px solid var(--input-border);
}

.newVacancy_instruction {
  margin: 0.2rem 0;
  margin-bottom: 1rem;
  color: grey;
  font-size: 1.4rem;
}

.newVacancy_div_bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.newVacancy_div_bottom_left {
  /* background-color: red; */
  display: flex;
}

.newVacancy_div_bottom_left_button {
  margin-right: 2rem;
  border: none;
  background-color: white;
  border: 1px solid var(--input-border);
  border-radius: 3px;
  padding: 0.2rem 0.6rem;
  text-align: center;
}

.newVacancy_div_bottom_left_button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.4rem;
}

.icon {
  margin-right: 1rem;
  font-size: 2rem !important;
  color: var(--primary-color);
}

.newVacancy_div_bottom_right {
  display: flex;
}

.newVacancy_div_bottom_right_confirm,
.newVacancy_div_bottom_right_cancel {
  margin-right: 3rem;
  background-color: var(--primary-color);
  border: none;
  color: var(--white-color);
  padding: 0.6rem 1rem;
  border-radius: 0.3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.newVacancy_div_bottom_right_cancel {
  background-color: rgb(159, 159, 159);
}

.newVacancy_div_bottom_right_icon {
  font-size: 2rem !important;
  margin-right: 0.5rem;
  font-weight: bold;
}

.review_image {
  height: 25rem;
  object-fit: cover;
  border: 1px solid var(--input-border);
}

.showImage {
  position: relative;
  margin-bottom: 2rem;
}

.cancel_image {
  position: absolute;
  top: 8px;
  right: 8px;
}

.cancel_icon {
  font-size: 2.5rem !important;
  color: grey;
  cursor: pointer;
}