.edit_profile {
  margin-top: 7rem;
  font-size: 1.6rem;
}
.ep-main-page {
  display: flex;
}

/* left box css parts here  */
.ep-left-box {
  grid-area: left;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  padding: 13vh 2vw;
  align-items: center;
  border-radius: 1.9rem;
  height: 98vh;
  /* background-color: var(--white-color); */
  /* box-shadow: 2px -2px 2px -2px rgba(0, 0, 0, 0.2); */

  flex: 0.3;
}

.ep-profile-pic {
  height: 25rem;
  border: 2.1px solid white;
  width: 25rem;
  border-radius: 50%;
  object-fit: cover;
  margin-top: -15%;
}

.change-pic-btn {
  margin: 6vh 1rem;
  padding: 1rem 2rem;
  border-radius: 1.2rem;
  background-color: var(--primary-color);
  color: white;
  font-size: large;
  cursor: pointer;
}

.change-pic-btn:hover {
  transform: scale(1.05);
}

/* right box css parts here  */
.ep-right-box {
  grid-area: right;
  background-color: white;
  display: flex;
  flex-direction: column;
  font-size: large;
  padding: 10vh 5vw;
  flex: 0.7;
}

.ep-input-gap {
  height: 3.5vh;
}

.ep-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25vh;
}

.ep-button {
  padding: 0.5rem 3rem;
  border-radius: 1.2rem;
  color: white;
  cursor: pointer;
}

.ep-button:hover {
  transform: scale(1.05);
}

.ep-submit-button {
  background-color: var(--primary-color);
}

.ep-cancel-button {
  background-color: var(--cancel-color);
}

.ep-buttons-gap {
  width: 5rem;
}

.ep-error-text {
  color: red;
  font-size: 1.3rem;
}

@media (max-width: 667px) {
  .ep-main-page {
    flex-direction: column;
  }

  .ep-left-box {
    height: fit-content;
    flex: 1;
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
  }
  .change-pic-btn {
    margin: 0;
    margin-top: 2rem;
  }
  .ep-profile-pic {
    height: 15rem;
    width: 15rem;
    margin-top: 0;
  }

  .ep-right-box {
    grid-area: right;
    background-color: white;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}
