.display_all_questions {
    margin-top: 7rem;
    padding: 3rem 8rem;
    font-size: 1.5rem;
}

.daq_question {
    font-weight: 600;
    margin-bottom: 0.3rem;
    white-space: pre-wrap;
}

.daq_question_div:hover {
    cursor: pointer;
    transform: scale(1.02);
}

.daq_pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;
}

.daq_pagination_btn {
    background-color: var(--primary-color);
    border: 0;
    border-radius: .8rem;
    padding: 0.8rem 2rem;
}

@media screen and (max-width: 488px) {
    .display_all_questions {
        padding: 2rem 4rem;
        font-size: 1.2rem;
    }
}