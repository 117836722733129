.profilePage {
  margin: 0rem;
  font-size: 1.6rem;
  display: flex;
  height: 100%;
  margin-top: 7rem;
  background-color: var(--body-color);
  padding: 1rem;
}

.profilePage_left {
  flex: 0.3;
  background-color: var(--white-color);
  box-shadow: 2px -2px 2px -2px rgba(0, 0, 0, 0.2);
  border-radius: 8px 8px 0% 0%;
}

.line {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid lightgray;
  line-height: 0.1em;
  margin: 10px 0 20px;
}

.line span {
  background: #fff;
  padding: 0 10px;
}

.profilePage_right {
  flex: 0.7;
  margin-left: 1rem;
  padding: 3.2rem 3rem;
  background-color: var(--white-color);
}

.profilePage_left_top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profilePage_left_top_grey {
  background-color: lightgray;
  border-radius: 8px 8px 0% 0%;
  height: 100px;
  width: 100%;
}

.profilePage_left_top_image {
  height: 12rem;
  border: 3px solid white;
  width: 12rem;
  border-radius: 50%;
  object-fit: cover;
  margin-top: -15%;
}

.profilePage_left_top_letter {
  height: 12rem;
  border: 3px solid white;
  width: 12rem;
  border-radius: 50%;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  background: lightgray;
  font-size: 4rem;
  margin-top: -15%;
}

.profilePage_left_name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profilePage_left_name_nm {
  font-size: 2.3rem;
  margin-bottom: 0.5rem;
}

.profilePage_left_name_pf {
  color: #5c5c5c;
}

.profilePage_left_bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.profilePage_left_bottom_item {
  height: 6rem;
  width: 80%;
  background-color: rgb(217, 217, 217);
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  flex-direction: column;
  border-radius: 8px;
}

.profilePage_left_bottom_item:nth-child(2) {
  flex-direction: row;
}

.profilePage_left_bottom_item:hover {
  box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.1);
}

.profilePage_left_bottom_item_span {
  margin-left: 0.5rem;
}

.margin-b-5 {
  margin-bottom: 0.5rem;
}

.profilePage_right_exams {
  margin-bottom: 20px;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
}

.profilePage_right_result_table {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.profilePage_right_result_table_heading {
  display: flex;
  background-color: rgb(217, 217, 217);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.profilePage_right_result_table_content {
  display: flex;
  justify-content: space-between;
}

.tw-1 {
  flex: 0.2;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
}

.tw-2 {
  flex: 0.4;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
}

.tw-3 {
  flex: 0.2;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
}

.tw-4 {
  flex: 0.2;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
}

.profilePage_right_result_table_item {
  margin-top: 0.4rem;
  background-color: var(--body-color);
}

@media (max-width: 1137px) {
  .profilePage_left_top_image {
    margin-top: -20%;
  }
}

@media (max-width: 875px) {
  .profilePage {
    display: flex;
    flex-direction: column;
  }

  .profilePage_left {
    flex: 1;
    background-color: var(--white-color);
    box-shadow: 0.8px 1px 2px -0.5px rgba(0, 0, 0, 0.2);
    margin-bottom: 3rem;
  }

  .profilePage_right {
    flex: 1;
    margin-left: 0rem;
    margin-top: 1rem;
    padding: 2rem 1rem;
    background-color: var(--white-color);
  }

  .profilePage_left_top_grey {
    height: 100px;
  }

  .profilePage_left_top_image {
    height: 13rem;
    border: 3px solid white;
    width: 13rem;
    border-radius: 50%;
    object-fit: cover;
    margin-top: -8%;
  }

  .profilePage_left_name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .profilePage_left_name_nm {
    font-size: 2.3rem;
    margin-bottom: 0.5rem;
  }

  .profilePage_left_name_pf {
    color: #5c5c5c;
  }

  .profilePage_left_bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
  }

  .profilePage_left_bottom_item {
    height: 6rem;
    width: 80%;
    background-color: rgb(217, 217, 217);
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    flex-direction: column;
  }

  .profilePage_left_bottom_item:nth-child(2) {
    flex-direction: row;
  }

  .profilePage_left_bottom_item:hover {
    box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.1);
  }

  .profilePage_left_bottom_item_span {
    margin-left: 0.5rem;
  }

  .margin-b-5 {
    margin-bottom: 0.5rem;
  }
}

@media (max-width: 588px) {
  .profilePage_left_top_image {
    margin-top: -20%;
  }

  .profilePage_right_exams {
    margin-top: 5px;
    grid-template-columns: repeat(1, 1fr);
  }

  .tw-1 {
    height: 4rem;
    text-align: center;
  }

  .tw-2 {
    text-align: center;
    height: 4rem;
  }

  .tw-3 {
    text-align: center;
    height: 4rem;
  }

  .tw-4 {
    text-align: center;
    height: 4rem;
  }
}

@media (max-width: 502px) {
  .tw-1 {
    flex: 0.15;
  }

  .tw-2 {
    flex: 0.4;
  }

  .tw-3 {
    flex: 0.225;
  }

  .tw-4 {
    flex: 0.225;
  }
}