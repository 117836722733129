.ask_question_main {
    margin-top: 7rem;
    padding: 6rem 8rem;
    font-size: 1.45rem;
    display: flex;
    justify-content: center;
    min-height: 98vh;
}

.aq_question_div {
    font-weight: 600;
    font-size: 1.65rem;
    margin-bottom: 1.8rem;
    white-space: pre-wrap;
}

.aq_answer_box {
    padding: 1rem 2rem;
    border: 2px solid gray;
    border-radius: 0.5rem;
    margin: 1rem 0;
}

.aq_answer_box:hover {
    transform: scale(1.02);
    cursor: pointer;
}

.aq_correct_ans_div {
    margin-top: 5rem;
}

.aq_explanation_div {
    margin: 2rem 0;
    font-size: 1.5rem;
}

.aq_explanation_box {
    white-space: pre-wrap;
}

@media screen and (max-width: 488px) {
    .ask_question_main {
        padding: 2rem 4rem;
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 360px) {
    .ask_question_main {
        padding: 2rem 3rem;
    }

    .aq_answer_box {
        padding: 1rem 1.5rem;
        border: 1.4px solid gray;
        border-radius: 0.5rem;
        margin: 1rem 0;
    }
}