.sidebar {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  font-size: 1.6rem;
  /* background-color: red; */
}
.sidebar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.sidebar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
