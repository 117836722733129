.page {
  background-color: var(--body-color);
  margin-top: 7rem;
  width: 100%;
  padding: 1rem;
}

.page_container {
  background-color: white;
  padding: 3rem;
}

.page_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 15px 0px 15px;
  /* background-color: red; */
}

.heading {
  font-size: 3rem;
  font-weight: 600;
}

.filter_btn {
  background-color: var(--primary-color);
  border: none;
  color: var(--white-color);
  height: 3.5rem;
  width: 10rem;
  font-size: 1.6rem;
}

.popup {
  backdrop-filter: blur(8px);
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
  color: black;
}

.listOftags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 80%;
  justify-content: space-around;
}

.selectedTags {
  display: flex;
  width: 90%;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  font-size: 13px;
}

.buttonsGroup {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
}

.vacancyBody {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 10px 8px 10px 8px;
  margin-top: 3rem;
}

.vacancySnap {
  display: flex;
  min-height: 225px;
  background-color: white;
  padding: 18px 40px;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0px 0px 13px 0px rgba(196, 196, 196, 0.75);
  -webkit-box-shadow: 0px 0px 13px 0px rgba(196, 196, 196, 0.75);
  gap: 2rem;
  -moz-box-shadow: 0px 0px 13px 0px rgba(196, 196, 196, 0.75);
}

.vacancyDescription {
  display: flex;
  flex-direction: column;
  width: 800px;
  gap: 4;
  padding: 0px 5px;
  font-size: 1.5rem;
  overflow: hidden;
}

.vacancyTitle {
  font-size: 25px;
  font-weight: 600;
}

.delete_popup {
  justify-content: center;
  align-items: center;
}

.vacancyDate {
  color: #787777;
  margin: 6px 0px;
}

.vacancyImage {
  background-color: var(--body-color);
  width: 260px;
  height: 190px;
  border-radius: 10px;
  border: 1px solid #ddd;
  background-position: center;
  background-repeat: no-repeat;
  object-fit: cover;
}

@media (max-width: 1090px) {
  .vacancyTitle {
    font-size: 1.82rem;
    font-weight: 600;
  }
}

@media (max-width: 900px) {
  .vacancyImage {
    width: 205px;
    height: 150px;
    border-radius: 10px;
  }

  .vacancyTitle {
    font-size: 1.72rem;
  }

  .vacancyDescription {
    font-size: 1.22rem;
  }

}

@media (max-width: 620px) {
  .vacancySnap {
    display: flex;
    min-height: 200px;
    flex-direction: column;
    align-items: start;
    justify-content: center;
  }

  .vacancyImage {
    width: 205px;
    height: 150px;
    border-radius: 10px;
  }

  .vacancy_img_div {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .vacancyDescription {
    font-size: 1.25rem;
    width: auto;
    margin: 0;
  }

  .vacancyTitle {
    font-size: 1.72rem;
  }
}

@media (max-width: 450px) {
  .vacancySnap {
    padding: 1.5rem 1.8rem;
  }

  .vacancyDescription {
    font-size: 1.18rem;
    width: auto;
    margin: 0;
  }

  .vacancyTitle {
    font-size: 1.42rem;
  }
}

@media (max-width: 352px) {
  .heading {
    font-size: 2.5rem;
  }

  .vacancyDescription {
    font-size: 1.18rem;
    width: auto;
    margin: 0;
  }

  .vacancyTitle {
    font-size: 1.42rem;
  }
}

@media (max-width: 284px) {
  .heading {
    font-size: 2rem;
  }

  .page {
    padding: 0.5rem;
  }

  .page_container {
    padding: 1rem;
  }

  .vacancySnap {
    padding: 1rem 0.6rem;
  }
}

/* How do we remove the space created by object-fit in css */