.sidebar-item {
  padding: 1rem;
  padding-right: 0;
  font-size: 1.6rem;
  display: block;
  cursor: pointer;
  /* border-bottom: 1px solid lightgray; */
}
/* .sidebar-item:hover {
  background-color: rgba(119, 119, 119, 0.1);
} */
.sidebar-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sidebar-title_icon {
  font-size: 3rem !important;
}

.sidebar-item.open > .sidebar-title .toggle-btn {
  transition: 0.3s;
  transform: rotate(180deg);
}
.sidebar-content {
  padding-top: 0.25rem;
  height: 0;
  overflow: hidden;
}

.sidebar-item.open > .sidebar-content {
  height: auto;
}
