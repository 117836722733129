.practiceExamCard {
  background-color: var(--white-color);
  padding: 20px;
  border-radius: 10px;
  margin: 3px;
  cursor: pointer;
  transition: all 0.4s ease;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 0px 13px 0px rgba(196, 196, 196, 0.75);
  -webkit-box-shadow: 0px 0px 13px 0px rgba(196, 196, 196, 0.75);
  -moz-box-shadow: 0px 0px 13px 0px rgba(196, 196, 196, 0.75);
}

.practiceExamCard:hover {
  transform: scale(1.05);
}

.practiceExamCard_top {
  display: flex;
  flex-direction: column;
}

.practiceExamCard_top_header {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
}

.practiceExamCard_top_content {
  font-size: 1.5rem;
  margin: 10px 0;
}

.practiceExamCard_top_header_content {
  font-size: 20px;
  font-weight: bold;
  white-space: pre-wrap;
}

.practiceExamCard_top_content_p {
  display: block;
  margin-bottom: 10px;
  margin-top: 5px;
  font-weight: 400;
  color: darkslategray;
}

.practiceExamCard_bottom_button {
  width: 100%;
  background-color: var(--primary-color);
  border: none;
  color: var(--white-color);
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  border-radius: 5px;
  margin-bottom: 5px;
  text-decoration: none;
}

.practiceExamCard_bottom_button:hover {
  color: white;
  box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.15);
}