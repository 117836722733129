.searchExam {
  padding: 1rem;
  margin-top: 6rem;
  font-size: 1.6rem;
  background-color: var(--body-color);
}
.searchExam_container {
  background-color: white;
}
.searchExam_bottom {
  margin-top: 1rem;
  min-height: 65vh;
  border-radius: 5px;
  padding: 15px 40px;
}

.searchExam_bottom_Title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 14px 23px 14px;
}

.searchExam_bottom_header {
  margin-left: 10px;
  font-size: 25px;
  font-weight: 700;
}

.searchExam_bottom_filterbtn {
  background-color: var(--primary-color);
  border: none;
  color: var(--white-color);
  height: 3.5rem;
  width: 10rem;
  font-size: 1.6rem;
  margin-left: 15px;
  cursor: pointer;
}

.searchExam_bottom_filterbtn:hover {
  box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.1);
}

.searchExam_button_exams_grid {
  display: grid;
  grid-gap: 16px;
}

@media (min-width: 768px) {
  .searchExam_button_exams_grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 960px) {
  .searchExam_button_exams_grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 637px) {
  .searchExam_bottom_header {
    font-size: 2.5rem;
  }

  .searchExam_bottom {
    padding: 2rem;
    padding-top: 2rem;
  }

  .searchExam {
    padding: 2rem;
  }
}

@media (max-width: 500px) {
  .searchExam_bottom_header {
    font-size: 2.2rem;
  }

  .searchExam_bottom {
    padding: 1.5rem;
    padding-top: 2rem;
  }

  .searchExam {
    padding: 1.5rem;
  }
}

@media (max-width: 380px) {
  .searchExam_bottom_header {
    font-size: 2rem;
  }

  .searchExam_bottom {
    padding: 1rem;
    padding-top: 2rem;
  }

  .searchExam {
    padding: 1rem;
  }
}
