.pending_cards_grid {
    display: grid;
    grid-template-columns: auto auto auto;
    padding: 10px;
}

.pending_card {
    background-color: var(--white-color);
    padding: 20px;
    border-radius: 10px;
    margin: 5px;
    min-width: 20vw;
    transition: all 0.4s ease;
    font-size: 1.6rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0px 0px 13px 0px rgba(196, 196, 196, 0.75);
    -webkit-box-shadow: 0px 0px 13px 0px rgba(196, 196, 196, 0.75);
    -moz-box-shadow: 0px 0px 13px 0px rgba(196, 196, 196, 0.75);
    overflow: hidden;
}

.pending_card:hover {
    transform: scale(1.05);
}

.pending_card_btn {
    cursor: pointer;
    margin: 0.6rem 0rem;
    padding: 0.5rem 2rem;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pending_card_red_btn {
    background-color: red;
    color: white;
}

.pending_card_green_btn {
    background-color: green;
    color: white;
}

.pending_card_popup {
    background-color: whitesmoke;
    color: "black";
    padding: 3rem 3.5rem;
    border-radius: 2rem;
    border: 2px solid grey;
    display: "flex";
    flex-direction: "column";
    align-items: "center";
}

.pending_card_popup_btn {
    margin: 0.4rem 2rem;
}

@media (max-width: 1240px) {
    .pending_card {
        min-width: 30vw;
    }
}