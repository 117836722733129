.question {
  font-size: 1.6rem;
  display: flex;
  padding: 0rem;
  margin-bottom: 2rem;
  width: 80%;
  margin-top: 2rem;
}

.question_btns{
  display: flex;
}

.edit_icon_question{
  margin-right: 20rem;
}

.explanation_div {
  white-space: pre-line;
}

.question_div {
  white-space: pre-line;
}

.displayImg {
  margin: 10px;
  justify-content: center;
  display: grid;
  grid-template-columns: auto auto auto;
}

.questionImg {
  object-fit: contain;
  margin: 15px;
  height: 120px;
}

.delete_popup {
  background: lightgray;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  height: 15vh;
  justify-content: space-around;
  padding: 20px;
  border-radius: 20px;
}

.delete_popup_title {
  margin-bottom: 10px;
}

.question_left {
  flex: 0.02;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.question_right {
  flex: 0.98;
  margin-left: 1rem;
}

.question_right {
  display: flex;
  flex-direction: column;
}

.question_right_top {
  margin-bottom: 2rem;
}

.question_right_bottom {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(2, 1fr);
}

.question_right_bottom_answer {
  border: 1px solid lightgrey;
  width: 98%;
  min-height: 4.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  cursor: pointer;
  padding: 0.5rem 1.2rem;
}

.question_right_bottom_answer:hover {
  box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.1);
}

.wrong_ans {
  background-color: var(--cancel-color);
  color: var(--white-color);
  border: none;
}

.right_ans {
  background-color: var(--submit-color);
  color: var(--white-color);
  border: none;
}

@media (max-width: 904px) {
  .question_right_bottom {
    display: block;
  }

  .question_right_bottom_answer {
    margin: 1.2rem 0rem;
  }
}

@media screen and (max-width: 755px) {
  .question {
    width: 90%;
  }

  .question_right_bottom_answer {
    width: 100%;
  }

  .displayImg {
    margin: 5px;
    justify-content: center;
    display: grid;
    grid-template-columns: auto auto auto;
  }

  .questionImg {
    object-fit: contain;
    margin: 5px;
    height: 80px;
  }
}