.vacancyDetail {
  background-color: var(--background-color);
  font-size: 1.6rem;
  margin-top: 9rem;
}

p {
  white-space: pre-line;
}

.vacancyDetail_div {
  display: flex;
  margin: 2rem;
}

.vacancyDetail_left {
  flex: 0.76;
  margin-right: 1rem;
  background-color: var(--white-color);
  padding: 3rem 4rem;
  box-shadow: -2px 0px 7px 0px rgba(209, 209, 209, 0.75);
  -webkit-box-shadow: -2px 0px 7px 0px rgba(209, 209, 209, 0.75);
  -moz-box-shadow: -2px 0px 7px 0px rgba(209, 209, 209, 0.75);
}

.vacancyDetail_left_header,
.vacancyDetail_right_header {
  font-size: 2.8rem;
  margin-bottom: 1.6rem;
  letter-spacing: 0.01rem;
}

.vacancyDetail_left_image {
  width: 100%;
  height: 35rem;
  /* object-fit: cover; */
  object-fit: contain;
  margin: 2rem 0;
}

.vacancyDetail_right {
  background-color: var(--white-color);
  padding: 3rem 1rem;
  flex: 0.24;
  box-shadow: -2px 0px 7px 0px rgba(209, 209, 209, 0.75);
  -webkit-box-shadow: -2px 0px 7px 0px rgba(209, 209, 209, 0.75);
  -moz-box-shadow: -2px 0px 7px 0px rgba(209, 209, 209, 0.75);
}

.vacancyDetail_left_subtitle {
  font-size: 2rem;
  margin: 1rem 0rem;
}

.vacancyDetail_left_paragraph {
  margin: 1rem 0rem;
}

.vd_share_button {
  margin-right: 8px;
}

.vd_share_button:hover {
  scale: 1.08;
}

.vd_insta_share_btn {
  font-size: 30px !important;
  color: red !important
}

@media screen and (max-width: 1060px) {
  .vacancyDetail_left {
    flex: 0.74;
  }

  .vacancyDetail_right {
    flex: 0.26;
  }
}

@media screen and (max-width: 988px) {
  .vacancyDetail_left {
    flex: 0.7;
  }

  .vacancyDetail_right {
    flex: 0.3;
  }
}

@media screen and (max-width: 877px) {
  .vacancyDetail_div {
    display: flex;
    flex-direction: column;
  }

  .vacancyDetail_right_div {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
  }

  .vacancyDetail_left {
    margin-right: 0rem;
    padding: 3rem 3rem;
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 817px) {
  .vacancyDetail_right_div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
  }
}

@media screen and (max-width: 488px) {
  .vacancyDetail_right_div {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    padding: 2rem;
    grid-gap: 1rem;
  }
}