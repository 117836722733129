.examCard {
  background-color: var(--white-color);
  padding: 20px;
  border-radius: 10px;
  margin: 3px;
  cursor: pointer;
  transition: all 0.4s ease;
  font-size: 1.6rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 0px 13px 0px rgba(196, 196, 196, 0.75);
  -webkit-box-shadow: 0px 0px 13px 0px rgba(196, 196, 196, 0.75);
  -moz-box-shadow: 0px 0px 13px 0px rgba(196, 196, 196, 0.75);
}

.examCard:hover {
  transform: scale(1.05);
}

.examCard_top {
  display: flex;
  flex-direction: column;
}

.examCard_top_header {
  padding: 5px 0;
  display: flex;
  justify-content: space-between;
}

.examCard_top_content {
  margin: 10px 0;
}

.examCard_top_header_content {
  font-size: 20px;
  font-weight: bold;
  white-space: pre-wrap;
}

.exam_add_photo {
  font-size: 2.5rem !important;
  cursor: pointer;
}

.exam_add_photo:hover {
  transform: scale(1.3);
}

.examCard_top_content_p {
  display: block;
  margin-bottom: 2.2px;
}

.examCard_bottom_button {
  width: 100%;
  border: none;
  color: var(--white-color);
  background-color: var(--primary-color);
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  border-radius: 5px;
  margin: 5px;
  text-decoration: none;
}

.examCard_bottom_button:hover {
  width: 100%;
  border: none;
  color: var(--white-color);
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  border-radius: 5px;
  margin-bottom: 5px;
  text-decoration: none;
}

.examCard_btns {
  display: flex;
  justify-content: center;
}

/* .green_btn {
  background-color: green;
}

.red_btn {
  background-color: red;
} */