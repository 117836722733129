.feature_card {
  background-color: #ffffff;
  box-shadow: 3px 4px 18px -4px rgba(135, 135, 135, 0.63);
  -webkit-box-shadow: 3px 4px 18px -4px rgba(135, 135, 135, 0.63);
  -moz-box-shadow: 3px 4px 18px -4px rgba(135, 135, 135, 0.63);
  width: 32rem;
  height: 30rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
  border-radius: 25px;
  position: relative;
  margin-top: 6rem;
}
.feature_card_header {
  text-align: center;
  margin-bottom: 2rem;
}
.feature_card_text {
  text-align: center;
}
.feature_card_image {
  height: 12rem;
  position: absolute;
  top: -5rem;
}
