.page {
  padding: 50px 100px;
  background-color: var(--body-color);
  margin-top: 5rem;
}

.page_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 15px 0px 15px;
}

.heading {
  font-size: 3rem;
  font-weight: 600;
}

.filter_btn {
  margin-left: 14px;
  background-color: var(--primary-color);
  border: none;
  color: var(--white-color);
  height: 3.5rem;
  width: 10rem;
  font-size: 1.6rem;
}

.popup {
  backdrop-filter: blur(8px);
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
  color: black;
}

.listOftags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 80%;
  justify-content: space-around;
}

.selectedTags {
  display: flex;
  width: 90%;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  font-size: 13px;
}

.buttonsGroup {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
}

.syllabus_body {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 10px 8px 10px 8px;
  margin-top: 3rem;
}

.syllabusSnap {
  display: flex;
  min-height: 225px;
  background-color: white;
  padding: 18px 40px;
  border-radius: 10px;
  box-shadow: 0px 0px 13px 0px rgba(196, 196, 196, 0.75);
  -webkit-box-shadow: 0px 0px 13px 0px rgba(196, 196, 196, 0.75);
  -moz-box-shadow: 0px 0px 13px 0px rgba(196, 196, 196, 0.75);
  gap: 35px;
  overflow: hidden;
}

.syllabusDescription {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  max-width: 800px;
  gap: 4;
  padding: 0px 5px;
  text-transform: none;
}

.syllabusTitle {
  font-size: 25px;
  font-weight: 600;
  text-transform: none;
}

.syllabusDate {
  color: #787777;
  margin: 4px 0px;
  font-size: 12px;
}

@media (max-width: 1090px) {
  .syllabusTitle {
    font-size: 1.82rem;
    font-weight: 600;
  }
}

@media (max-width: 900px) {
  .syllabusImage {
    width: 205px;
    height: 150px;
    border-radius: 10px;
  }

  .syllabusTitle {
    font-size: 2.02rem;
  }

  .syllabusDescription {
    font-size: 1.6rem;
  }
}

@media (max-width: 780px) {
  .syllabusTitle {
    font-size: 1.8rem;
  }

  .syllabusDescription {
    font-size: 1.32rem;
  }
}

@media (max-width: 620px) {
  .syllabusSnap {
    display: flex;
    min-height: 200px;
    flex-direction: column;
    align-items: start;
    justify-content: center;
  }

  .syllabusImage {
    width: 205px;
    height: 150px;
    border-radius: 10px;
  }

  .syllabus_img_div {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .syllabusDescription {
    font-size: 1.25rem;
    width: auto;
    margin: 0;
  }

  .syllabusTitle {
    font-size: 1.72rem;
  }
}

@media (max-width: 450px) {
  .syllabusSnap {
    padding: 1.5rem 1.8rem;
  }

  .syllabusDescription {
    font-size: 1.3rem;
    width: auto;
    margin: 0;
  }

  .syllabusTitle {
    font-size: 1.58rem;
  }
}

@media (max-width: 352px) {
  .heading {
    font-size: 2.5rem;
  }

  .syllabusDescription {
    font-size: 1.18rem;
    width: auto;
    margin: 0;
  }

  .syllabusTitle {
    font-size: 1.42rem;
  }
}

@media (max-width: 284px) {
  .heading {
    font-size: 2rem;
  }

  .page {
    padding: 0.5rem;
  }

  .page_container {
    padding: 1rem;
  }

  .syllabusSnap {
    padding: 1rem 0.6rem;
  }
}

/* How do we remove the space created by object-fit in css */