.success {
  margin-top: 8rem;
  font-size: 1.6rem;
  padding: 3rem;
}
.success_top {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.success_top_header {
  font-size: 3rem;
  font-weight: bold;
}
.success_below_container_header {
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
}

.success_button {
  margin-top: 3rem;
  display: flex;
  justify-content: space-around;
}

.success_button_item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  margin-right: 1rem;
}
.success_below_container {
  margin-top: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.success_button_item_text {
  text-align: center;
}
.Success_button_item_header {
  font-size: 5rem;
  background: linear-gradient(90deg, #9b85ff 0%, #49bbbd 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-align: center;
}

.success_below_container_text,
.success_top_text {
  color: var(--subtext-color);
  width: 50%;
  text-align: center;
  margin-top: 1rem;
}

.success_features {
  /* background-color: red; */
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 6rem;
}

@media (max-width: 1024px) {
  .success_top_text {
    width: 70%;
  }
}

@media (max-width: 915px) {
  .success_button {
    flex-wrap: wrap;
  }
}
@media (max-width: 844px) {
  .success_below_container_text,
  .success_top_text {
    width: 80%;
  }
}
@media (max-width: 723px) {
  .success_top_text {
    width: 70%;
  }

  .success_below_container_text,
  .success_top_text {
    width: 100%;
  }
}
@media (max-width: 666px) {
  .success_button {
    flex-wrap: wrap;
  }
}

@media (max-width: 561px) {
  .success_top_text {
    width: 100%;
  }
}
