.sliderStyles {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hero_container_right_image {
    height: 48rem;
    border-radius: 2rem 2rem 10rem 2rem;
    margin-right: 2.5rem;
}

.dotsContainerStyles {
    display: flex;
    justify-content: center;
}

.dotStyle {
    margin: 0rem 0.3rem;
    cursor: pointer;
    color: var(--primary-color);
    font-size: 1.8rem;
}

.dotSelected {
    color: black;
}

@media (max-width: 1300px) {
    .hero_container_right_image {
        height: 42rem;
        margin-right: 1.3rem;
    }
}

@media (max-width: 1263px) {
    .hero_container_right_image {
        height: 41rem;
        margin-right: 1rem;
    }
}

@media (max-width: 1095px) {
    .hero_container_right_image {
        height: 38rem;
    }
}

@media (max-width: 930px) {
    .hero_container_right_image {
        width: 100vw;
        height: auto;
        margin: 0;
        border-radius: 0rem;
    }

    .sliderStyles {
        margin: 0;
        justify-content: start;
        height: auto;
    }

    .dotStyle {
        margin: 0rem 0.3rem;
        font-size: 2.1rem;
    }

}

@media (max-width: 500px) {
    .dotStyle {
        font-size: 1.6rem;
    }

}