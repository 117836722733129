.practice_page_body {
  display: flex;
  padding: 1rem;
  /* height: 100vh; */
  background-color: var(--body-color);
  font-size: 1.6rem;
  margin-top: 7rem;
}

.practice_page_body_left {
  flex: 0.25;
  background-color: var(--white-color);
}

.practice_page_body_right {
  flex: 0.75;
  margin-left: 1rem;
  background-color: var(--white-color);
}

.practice_page_body_right_top {
  display: none;
}

.practice_page_body_right_top_button {
  margin: 2rem 0;
  border: none;
  background-color: var(--primary-color);
  color: var(--white-color);
  font-size: 1.8rem;
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  width: 8rem;
}

.practice_page_body_right_content {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  padding: 2rem;
}

.practice_page_body_right_content::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.practice_page_body_right_content {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.breadcrumbs {
  margin: 1.5rem 0 1rem 2rem;
  font-size: 1.4rem;
  color: gray;
  display: inline;
  justify-content: center;
}

.breadcrumbs_highlight {
  color: var(--primary-color);
}

@media screen and (max-width: 790px) {

  /* .practice_page_body {
    flex-direction: column;
  }
  .practice_page_body_left {
    flex: 1;
    background-color: var(--white-color);
  }
  */
  .practice_page_body_right {
    /* margin-top: 1rem; */
    flex: 1;
    margin-left: 0rem;
  }

  .practice_page_body_left {
    display: none;
  }

  .practice_page_body_right_top {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}