.PracticeExam {
  padding: 1rem;
  margin-top: 7rem;
  font-size: 1.6rem;
  background-color: var(--body-color);
}
.PracticeExam_body {
  display: flex;
  height: 85vh;
}

.PracticeExam_body_left {
  flex: 0.7;
  margin-right: 1rem;
  background-color: var(--white-color);
  padding: 2rem 0;
}
.PracticeExam_body_left_top {
  display: flex;
  justify-content: space-between;
}
.PracticeExam_body_right_header {
  text-align: center;
  margin: 2rem 0;
}
.PracticeExam_body_left_top_left {
  display: flex;
}
.green {
  color: var(--submit-color);
}
.red {
  color: var(--cancel-color);
}
.golden {
  color: var(--golden-color);
}
.PracticeExam_body_left_top_left_content {
  margin-left: 2rem;
}
.PracticeExam_body_left_top_right {
  margin-right: 2rem;
}
.PracticeExam_body_right {
  flex: 0.3;
  background-color: var(--white-color);
}
.PracticeExam_body_left_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.PracticeExam_body_left_mid {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6rem;
  /* background-color: red; */
}
.PracticeExam_body_left_bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}
.PracticeExam_body_left_bottom_mid {
  margin: 0 5rem;

  height: 4rem;
  width: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: goldenrod;
  border: none;
  color: var(--white-color);
}
.PracticeExam_body_left_bottom_left,
.PracticeExam_body_left_bottom_right {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  width: 15rem;
}
.PracticeExam_body_left_bottom_left:hover,
.PracticeExam_body_left_bottom_right:hover,
.PracticeExam_body_left_bottom_mid:hover {
  box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.PracticeExam_body_right_grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
  margin: 1rem;
  text-align: center;
}
.PracticeExam_body_right_item {
  background-color: lightgray;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.PracticeExam_body_right_item:hover {
  cursor: pointer;
  box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.1);
}
.PracticeExam_body_right_button {
  margin-top: 2rem;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.PracticeExam_body_right_button_submit,
.PracticeExam_body_right_button_cancel {
  height: 3.5rem;
  width: 9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
}
.PracticeExam_body_right_button_submit {
  background-color: var(--submit-color);
  margin-right: 3rem;
}
.PracticeExam_body_right_button_cancel {
  background-color: var(--cancel-color);
}
.PracticeExam_body_right_button_submit:hover,
.PracticeExam_body_right_button_cancel:hover {
  box-shadow: inset 0 0 200px 200px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
@media screen and (max-width: 880px) {
  .PracticeExam_body_left_bottom_left,
  .PracticeExam_body_left_bottom_right {
    height: 4rem;
    width: 10rem;
  }
}
@media screen and (max-width: 822px) {
  .PracticeExam_body_right_grid {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0.5rem;
  }
  .PracticeExam_body_right_button_submit {
    margin-right: 2rem;
  }
  .PracticeExam_body_right_button_submit,
  .PracticeExam_body_right_button_cancel {
    height: 3.5rem;
    width: 7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white-color);
  }
}
@media screen and (max-width: 755px) {
  .PracticeExam_body {
    flex-direction: column;
  }
  .PracticeExam_body_left_mid {
    margin-top: 0rem;
    /* background-color: red; */
  }
  .PracticeExam_body {
    display: flex;
    height: 100%;
  }
  .PracticeExam_body_right {
    margin-top: 1rem;
    padding: 2rem;
  }
  .PracticeExam_body_right_grid {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;
  }
  .PracticeExam_body_left {
    margin-right: 0;
    /* background-color: red; */
    height: fit-content;
  }
  .PracticeExam_body_left_bottom {
    margin-top: 2rem;
  }
  .PracticeExam_body_left_bottom_mid {
    margin: 0 3rem;
  }
}
@media screen and (max-width: 499px) {
  .PracticeExam_body_left_top {
    flex-direction: column;
  }
  .PracticeExam_body_left_top_right {
    margin-right: 0;
    margin-top: 0.5rem;
  }
}
@media screen and (max-width: 387px) {
  .PracticeExam_body_left_bottom_mid {
    margin: 0 1rem;
  }
}
