.container-slider {
  max-width: 380px;
  height: 450px;
  position: relative;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  font-size: 1.6rem;
}

.slide {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  transition: opacity ease-in-out 0.4s;
}
.slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.active-anim {
  opacity: 1;
}

.btn-slide {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #f1f1f1;
  border: 1px solid rgba(34, 34, 34, 0.287);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btn-slide img {
  width: 25px;
  height: 25px;
  pointer-events: none;
}

.next {
  top: 50%;
  right: 0px;
  transform: translateY(-60%);
  color: #49bbbd !important;
  font-weight: bold;
  background-color: white;
}

.review {
  position: absolute;
  z-index: 100000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-left: 8px solid #f67766;
  border-radius: 6px;
  top: 70%;
  left: 10%;
  width: 50rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  padding: 1.5rem;
}
.review_right_top {
  border-left: 1px solid #bdbdd1;
  padding-left: 1rem;
  color: var(--subtext-color);
}
.review_right_bottom {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.star_icon {
  color: #fba333;
  font-size: 1.8rem !important;
}
.review_right_bottom_right_top {
  display: flex;
  justify-content: flex-end;
}
.review_right_bottom_right_bottom {
  margin-top: 0.3rem;
  font-size: 1.4rem;
  color: var(--subtext-color);
  display: flex;
  justify-content: flex-end;
}
.review_right_bottom_left {
  padding-left: 1rem;
  color: var(--subtext-color);
}
@media (max-width: 1255px) {
  .container-slider {
    width: 370px;
    height: 440px;
  }
  .review {
    width: 45rem;
  }
}
@media (max-width: 1135px) {
  .container-slider {
    width: 350px;
    height: 440px;
  }
  .review {
    width: 40rem;
  }
}
@media (max-width: 600px) {
  .testimonial {
    padding: 2rem;
  }
  .container-slider {
    width: 320px;
    height: 430px;
  }
  .review {
    width: 36rem;
  }
}
@media (max-width: 510px) {
  .container-slider {
    width: 280px;
    height: 400px;
  }
  .review {
    width: 28rem;
  }
}
@media (max-width: 375px) {
  .container-slider {
    width: 280px;
    height: 400px;
  }
  .review {
    width: 28rem;
  }
}
