.testimonial {
  display: flex;
  flex-direction: row;
  margin-top: 4rem;
  margin-bottom: 14rem;
  font-size: 1.6rem;
  padding: 3rem;
  overflow: hidden;
}

.testimonial_left {
  flex: 1.1;
  display: flex;
  justify-content: center;
}

.testimonial_right {
  flex: 0.9;
}

.testimonial_left_topHead {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.testimonial_left_topHead_line {
  width: 40px;
  border: 1px solid var(--subtext-color);
}

.testimonial_left_topHead_text {
  margin-left: 0.5rem;
  color: var(--subtext-color);
  letter-spacing: 0.05rem;
}

.testimonial_header {
  margin-top: 2rem;
  font-size: 3rem;
  letter-spacing: 0.03rem;
}

.paragraph {
  display: flex;
  flex-direction: column;
  color: var(--subtext-color);
}

.paragraph_text {
  margin-top: 2rem;
}

.textimonial_left_div {
  width: 80%;
  /* justify-content: center; */
}

.testimonial_bottom {
  margin-top: 3rem;
  font-size: 1.8rem;
  border: 1px solid #49bbbd;
  padding: 1rem 2rem;
  width: fit-content;
  border-radius: 4rem;
  color: #49bbbd;
  cursor: pointer;
}

.testimonial_bottom a {
  text-decoration: none;
  color: #49bbbd;
}

@media (max-width: 1021px) {
  .testimonial_left {
    flex: 1;
  }

  .testimonial_right {
    flex: 1;
  }
}

@media (max-width: 924px) {
  .testimonial_left {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .paragraph {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .paragraph_text {
    text-align: center;
  }

  .textimonial_left_div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .testimonial_right {
    flex: 1;
    margin-top: 5rem;
    background-color: red;
    margin-bottom: 10rem;
  }

  .testimonial {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}