.LiveExamComponent {
  margin-top: 8rem;
  font-size: 1.6rem;
  padding: 3rem;
  background-color: var(--primary-light);
}
.LiveExamComponent_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.LiveExamComponent_link {
  font-weight: bold;
  text-decoration: none;
  padding: 0.3rem 0.6rem;
}
.LiveExamComponent_link:hover {
  background: #bdd2f2;
  border-radius: 5px;
}
.LiveExamComponent_mid {
  display: grid;
  gap: 4rem;
  grid-auto-flow: column;
  grid-auto-columns: 25%;
  overflow-x: auto;
  overscroll-behavior-inline: contain;
  padding: 0 2rem 5rem;
}
.LiveExamComponent_mid_items {
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.LiveExamComponent_top_header {
  font-size: 2.5rem;
}
@media (max-width: 1050px) {
  .LiveExamComponent_mid {
    justify-content: space-around;
  }
}
@media (max-width: 1000px) {
  .LiveExamComponent {
    margin-top: 4rem;
  }
}

@media (max-width: 1323px) {
  .LiveExamComponent_mid {
    grid-auto-columns: 29%;
  }
}
@media (max-width: 1122px) {
  .LiveExamComponent_mid {
    grid-auto-columns: 35%;
  }
}
@media (max-width: 864px) {
  .LiveExamComponent_mid {
    grid-auto-columns: 40%;
  }
}
@media (max-width: 747px) {
  .LiveExamComponent_mid {
    grid-auto-columns: 45%;
  }
}
@media (max-width: 660px) {
  .LiveExamComponent_mid {
    grid-auto-columns: 49%;
  }
}
@media (max-width: 644px) {
  .LiveExamComponent_mid {
    grid-auto-columns: 60%;
  }
}
@media (max-width: 578px) {
  .LiveExamComponent_mid {
    grid-auto-columns: 87%;
  }
}
