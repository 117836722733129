.recentVacancy {
  /* background-color: red; */
  width: 100%;
  border: 1px solid var(--input-border);
  padding: 0.5rem;
  margin-bottom: 2rem;
  /* margin-right: 1rem; */
  /* background-color: red; */
}
.recentVacancy_top_image {
  width: 100%;
  height: 18rem;
  object-fit: cover;
}
.recentVacancy_bottom_para {
  font-weight: 600;
  margin-top: 1rem;
  letter-spacing: 0.01rem;
}
