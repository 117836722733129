.scoreboard {
  height: 100%;
  font-size: 16px;
  background-color: white;
  margin: 6rem 1rem;
}

.scoreboard_top {
  display: grid;
  grid-template-columns: auto auto;
  gap: 15px;
  column-gap: 4rem;
  background-color: var(--white-color);
  padding: 10px;
  padding: 3rem 5rem;
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);
}

.scoreboard_top_left {
  flex: 0.4;
  background-color: var(--white-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: 8px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.scoreboard_top_left_part {
  display: flex;
  flex-direction: row;
  justify-content: start;
}

.scoreboard_top_left_top_title,
.scoreboard_top_right_top_title {
  font-size: 2rem;
  font-weight: bolder;
  color: black;
}

.scoreboard_top_left_top_value {
  margin-left: 5px;
  font-size: 2rem;
  color: black;
  /* word-wrap: break-word; */
  /* width: 30vw; */
}

.scoreboard_top_right_top_value {
  margin-left: 5px;
  font-size: 2rem;
  color: black;
  word-wrap: break-word;
}

.scoreboard_top_right {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 15px;
  column-gap: 4rem;
  background-color: var(--white-color);
  padding: 10px;
  padding: 3rem 5rem;
}

.scoreboard_top_right_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.outer_div {
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 0;
}

.scoreboard_bottom {
  background-color: var(--white-color);
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  padding: 18px 8px;
}

.scoreboard_bottom_header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.scoreboard_bottom_mistakes {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.scoreboard_bottom_mistakes_div {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.correct_status {
  color: green;
  font-weight: bold;
}

.incorrect_status {
  color: red;
  font-weight: bold;
}

.correct_ans {
  background-color: green;
  color: white;
}

.incorrect_ans {
  background-color: red;
  color: white;
}

.status_and_marks {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  margin-bottom: 0.7rem;
}

@media (max-width: 1177px) {
  .outer_div {
    width: 60%;
  }
}

@media (max-width: 979px) {
  .outer_div {
    width: 70%;
  }

  .scoreboard_bottom_mistakes_div {
    width: 90%;
  }


  .scoreboard_top {
    display: grid;
    grid-template-columns: auto;
    gap: 15px;
    column-gap: 4rem;
    background-color: var(--white-color);
    padding: 1.5rem;
  }

  .scoreboard_top_left {
    border-right: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding-bottom: 2rem;
  }
}

@media (max-width: 841px) {
  .outer_div {
    width: 80%;
  }
}

@media (max-width: 738px) {
  .outer_div {
    width: 90%;
  }

  .scoreboard_bottom_mistakes_div {
    width: 90%;
  }
}

@media (max-width: 695px) {

  .scoreboard_top_right_top_title,
  .scoreboard_top_left_top_title {
    text-align: center;
  }

  .scoreboard_top_right {
    height: 35rem;
  }
}

@media (max-width: 474px) {
  .scoreboard_top_right {
    display: grid;
    grid-template-columns: auto auto;
    gap: 15px;
    column-gap: 4rem;
    background-color: var(--white-color);
    padding: 1rem;
  }

  .scoreboard_top_left_top_value {
    margin-left: 5px;
    font-size: 2rem;
    color: black;
    word-wrap: break-word;
    width: 70vw;
  }

}

@media (max-width: 398px) {
  .scoreboard_top_left_top_value {
    word-wrap: break-word;
    width: 60vw;
  }

}