.sidebarMob {
  position: fixed;
  top: 0;
  z-index: 100;
  background-color: var(--white-color);
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  animation: animateDiv 0.5s ease-out forwards;
  font-size: 1.6rem;
  margin-top: 7rem;
}

.sidebarMob_head {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
  padding: 2rem;
}
.sidebarMob_head span {
  font-size: 1.8rem;
  font-weight: 600;
}
.sidebarMob_close {
  font-size: 2.5rem !important;
  font-weight: bold !important;
  cursor: pointer;
}
.sidebarMod_item {
  padding: 1rem;
  padding-right: 2rem;
}

@keyframes animateDiv {
  0% {
    transform: translateY(-100vh);
  }
  100% {
    transform: translateY(0);
  }
}
