.practiceDetail {
  font-size: 1.6rem;
  /* margin: 4rem 5rem; */
  background-color: var(--body-color);
  padding: 1rem;
  margin-top: 7rem;
}

.practiceDetial_body {
  display: flex;
}

.practiceDetial_header {
  background-color: var(--white-color);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  font-weight: bold;
  height: 5rem;
  margin-bottom: 1rem;
}

.practiceDetail_body_left {
  flex: 0.8;
  padding: 3rem 5rem;
  background-color: var(--white-color);
}

.practiceDetail_body_left_title {
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 0.03rem;
  margin-bottom: 1rem;
}

.practiceDetail_body_left_body {
  margin-bottom: 2rem;
  letter-spacing: 0.03rem;
  line-height: 2.3rem;
  white-space: pre-wrap;
}

.practiceDetail_body_right {
  flex: 0.2;
  background-color: var(--white-color);
  margin-left: 1rem;
}

.practiceDetail_body_left_detail_item {
  margin-bottom: 1rem;
}

.practiceDetail_body_right_top {
  background-color: var(--body-color);
  border-radius: 10px;
  margin: 1rem;
  padding: 2rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.practiceDetail_body_right_top_price {
  margin-bottom: 0.5rem;
  font-size: 2.5rem;
}

.practiceDetail_body_right_top_value {
  margin-bottom: 1rem;
  font-size: 2.5rem;
}

.practiceDetail_body_right_top_button button {
  background-color: var(--primary-color);
  border: none;
  color: var(--white-color);
  height: 4rem;
  width: 12rem;
  border-radius: 10px;
  font-size: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.practiceDetail_body_right_top_button button:hover {
  box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .practiceDetial_body {
    flex-direction: column;
  }

  .practiceDetail_body_right {
    margin-top: 1rem;
    margin-left: 0;
  }

  .practiceDetail {
    font-size: 1.6rem;
    margin: 5rem 1rem;
  }

  .practiceDetail_body_left_title {
    text-align: center;
  }
}
