.about_loksewa {
  margin-top: 2rem;
  padding: 3rem;
  font-size: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}

.about_loksewa_top {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.about_loksewa_top_text {
  color: var(--subtext-color);
  text-align: center;
  margin-top: 2rem;
  width: 80%;
}

.about_loksewa_mid {
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about_loksewa_mid_left {
  background-image: url("../../assets/forBeginner.png");
  height: 27rem;
  width: 40rem;
  background-position: center;
  background-size: cover;
  background-blend-mode: darken;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--white-color);
  box-shadow: inset 900px 0 0 rgba(0, 0, 0, 0.4);
  margin-right: 3rem;
}

.about_loksewa_mid_text {
  font-size: 1.8rem;
  letter-spacing: 0.05rem;
  font-weight: bold;
}

.about_loksewa_mid_link {
  text-decoration: none;
  color: var(--white-color);
  margin-top: 2rem;
  font-weight: bold;
  letter-spacing: 0.08rem;
  border: 1px solid var(--white-color);
  padding: 1.3rem 3rem;
  border-radius: 50px;
}

.about_loksewa_mid_link:hover {
  color: black;
  background-color: var(--white-color);
}

.about_loksewa_mid_right {
  background-image: url("../../assets/forBeginner.png");
  height: 27rem;
  width: 40rem;
  background-position: center;
  background-size: cover;
  background-blend-mode: darken;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--white-color);
  box-shadow: inset 900px 0 0 rgba(0, 0, 0, 0.4);
}

.coloured_button {
  background-color: var(--primary-color);
  border: var(--primary-color);
}

.coloured_button:hover {
  color: var(--primary-color);
}

.about_loksewa_bottom {
  display: flex;
  justify-content: space-between;

  margin-top: 8rem;
  width: 80%;
}

.about_loksewa_bottom_left {
  flex: 1.2;
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
  justify-content: center;
  z-index: -100;
}

.decoration {
  height: 4rem;
  width: 4rem;
  background-color: var(--primary-color);
  border-radius: 50%;
  position: absolute;
  z-index: -100;
  left: -1rem;
  top: 0;
}

.decoration_two {
  height: 2rem;
  width: 2rem;
  background-color: var(--primary-color);
  border-radius: 50%;
  position: absolute;
  z-index: -100;
  top: 20rem;
  right: 0;
}

.about_loksewa_bottom_right {
  flex: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about_loksewa_bottom_right_image {
  width: 100%;
}

.about_loksewa_bottom_left_header {
  font-size: 2.5rem;
  position: relative;
}

.primary_color {
  color: var(--primary-color);
}

.subtext_color {
  color: var(--subtext-color);
}

.margin_bottom-2 {
  margin-bottom: 2rem;
}

.about_loksewa_bottom_left_link {
  color: var(--primary-color);
  text-decoration: none;
  margin-top: 0.5rem;
}

@media (max-width: 1124px) {
  .about_loksewa_bottom {
    width: 100%;
    padding: 0 5rem;
  }
}

@media (max-width: 1115px) {
  .about_loksewa_bottom_right {
    flex: 0.9;
  }

  .about_loksewa_bottom_left {
    flex: 1.1;
  }
}

@media (max-width: 985px) {
  .about_loksewa_bottom {
    width: 100%;
    padding: 0 0rem;
  }
}

@media (max-width: 890px) {
  .about_loksewa_mid_right {
    height: 25rem;
    width: 36rem;
  }

  .about_loksewa_mid_left {
    height: 25rem;
    width: 36rem;
  }
}

@media (max-width: 814px) {
  .about_loksewa_mid_right {
    height: 20rem;
    width: 33rem;
  }

  .about_loksewa_mid_left {
    height: 20rem;
    width: 33rem;
  }
}

@media (max-width: 724px) {
  .about_loksewa_mid_right {
    height: 20rem;
    width: 29rem;
  }

  .about_loksewa_mid_left {
    height: 20rem;
    width: 29rem;
  }
}

@media (max-width: 644px) {
  .about_loksewa_bottom {
    /* background-color: red; */
    flex-direction: column-reverse;
  }

  .about_loksewa_bottom_right {
    flex: 1;
    /* background-color: red; */
    margin-bottom: 5rem;
    margin-top: 3rem;
  }

  .about_loksewa_bottom_left {
    margin-right: 0;
    flex: 1;
    /* width: 80%; */
  }

  .about_loksewa_bottom_right_image {
    width: 90%;
  }

  .about_loksewa_mid {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .about_loksewa_mid_left {
    margin: 0;
    margin-bottom: 2rem;
    height: 28rem;
    width: 38rem;
  }

  .about_loksewa_mid_right {
    margin: 0;
    height: 28rem;
    width: 38rem;
  }
}

@media (max-width: 535px) {
  .about_loksewa_bottom_right_image {
    width: 100%;
  }
}

@media (max-width: 487px) {
  .about_loksewa_bottom_right_image {
    width: 100%;
  }

  .decoration_two {
    top: 16rem;
  }
}

@media (max-width: 406px) {
  .about_loksewa_mid_left {
    height: 24rem;
    width: 35rem;
  }

  .about_loksewa_mid_right {
    height: 24rem;
    width: 35rem;
  }

  .about_loksewa_bottom_right_image {
    width: 100%;
  }
}

@media (max-width: 375px) {
  .about_loksewa_mid_left {
    height: 24rem;
    width: 32rem;
  }

  .about_loksewa_mid_right {
    height: 24rem;
    width: 32rem;
  }

  .about_loksewa_bottom_right_image {
    width: 100%;
  }
}

@media (max-width: 285px) {
  .about_loksewa_mid_left {
    /* height: 20rem; */
    width: 90vw;
  }

  .about_loksewa_mid_right {
    /* height: 20rem; */
    width: 90vw;

  }

  .about_loksewa_bottom_right_image {
    width: 100%;
  }
}