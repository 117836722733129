.add_qr_code_page {
    min-height: 90vh;
    padding: 3rem 5rem;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-direction: column;
}

.add_qr_info {
    display: flex;
    justify-content: space-between;
    font-size: large;
}

.add_qr_info_item {
    display: flex;
    flex-direction: column;
    margin: 1rem 2rem;
}

.add_qr_info_value {
    background-color: gray;
    margin: 0.5rem 0;
    border-radius: 1rem;
    padding: 1rem 2rem;
    color: white;
    cursor: pointer;
}

.add_qr_img {
    height: 25vh;
    margin-bottom: 2rem;
}

.add_qr_btns {
    display: flex;
    justify-content: center;
    font-size: large;
}

.add_qr_btn {
    color: white;
    padding: 1rem 2rem;
    margin: 0.2rem 2rem;
    border-radius: 1rem;
    cursor: pointer;
}

.green_btn {
    background-color: green;
}

.red_btn {
    background-color: red;
}