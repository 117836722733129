.sidebar_container {
  color: black;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
}
.backdrop_nav {
  width: 65vw;
}
.sidebar_container_div {
  background-color: white;
  animation-name: example;
  animation-duration: 0.5s;
  width: 35vw;
  height: 100%;
  padding: 2rem;
}
.sidebar_container_div_item_header {
  display: flex;
  justify-content: center;
  align-items: center;
  animation-name: fade;
  animation-duration: 3s;
}
.sidebar_container_div_item {
  border-top: 1px solid rgb(223, 223, 223);
  padding: 1.5rem 2rem;
  animation-name: fade;
  animation-duration: 3s;
  cursor: pointer;
}
.sidebar_container_div_item:hover {
  background-color: rgb(238, 238, 238);
  color: black;
}

.special_links {
  margin-top: 5rem;
}

.side_nav_logs {
  display: flex;
  justify-content: space-between;
}

.sidebar_container_div_item:first-child {
  border: none;
  padding: 0;
}

@keyframes example {
  0% {
    width: 0;
  }

  100% {
    width: 40vw;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media (min-width: 1080px) {
  .sidebar_container {
    display: none;
  }
}
@media (max-width: 866px) {
  .backdrop_nav {
    width: 60vw;
  }
  .sidebar_container_div {
    width: 40vw;
  }
}
@media (max-width: 737px) {
  .backdrop_nav {
    width: 50vw;
  }
  .sidebar_container_div {
    width: 50vw;
  }
}
@media (max-width: 554px) {
  .backdrop_nav {
    width: 40vw;
  }
  .sidebar_container_div {
    width: 60vw;
  }
}
@media (max-width: 447px) {
  .backdrop_nav {
    width: 30vw;
  }
  .sidebar_container_div {
    width: 70vw;
  }
}
