body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  background-color: var(--body-color);
}
html {
  font-size: 62.5%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
:root {
  --background-color: #fafafa;
  --primary-color: #9b85ff;
  --primary-light: #e2dcfd;

  --primaryLight-color: #e2dcfd;
  --white-color: white;
  --body-color: #eeeeee;
  --submit-color: #4caf50;
  --cancel-color: #dd1919;
  --golden-color: goldenrod;
  --input-border: #cacaca;
  --text-color: #2f327d;
  --subtext-color: #696984;
}
