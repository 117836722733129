.examDetail {
  font-size: 1.6rem;
  /* margin: 3rem 5rem; */
  margin-top: 7rem;
  background-color: var(--body-color);
  padding: 1rem;
}

.examDetial_body {
  display: flex;
}

.examDetial_header {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  font-weight: bold;
  height: 5rem;
  margin-bottom: 1rem;
  background-color: white;
}

.examDetail_body_left {
  flex: 0.8;
  padding: 2rem 3rem;
  background-color: var(--white-color);
}

.examDetail_body_left_title {
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 0.03rem;
  margin-bottom: 2rem;
}

.examDetail_body_left_body {
  margin-bottom: 2rem;
  letter-spacing: 0.03rem;
  line-height: 2.3rem;
}

.examDetail_body_right {
  flex: 0.2;
  background-color: var(--white-color);
  margin-left: 1rem;
}

.examDetail_body_left_detail_item {
  margin-bottom: 1rem;
}

.examDetail_body_right_top {
  background-color: var(--body-color);
  margin: 1rem;
  padding: 2rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
}

.examDetail_body_right_top_price {
  margin-bottom: 0.5rem;
  font-size: 2.5rem;
}

.examDetail_body_right_top_value {
  margin-bottom: 1rem;
  font-size: 2.5rem;
}

.examDetail_body_right_top_button button {
  background-color: var(--primary-color);
  border: none;
  color: var(--white-color);
  padding: 0.6rem 1.5rem;
  font-size: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.examDetail_popup_close_button button {
  background-color: var(--primary-color);
  border: none;
  color: var(--white-color);
  padding: 5px 18px;
  font-size: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.examDetail_body_right_top_button button:hover {
  box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.exam_detail_display_qr {
  width: 24vw;
}

.examDetail_popup_btn_grp {
  display: flex;
  justify-content: center;
  align-items: center;
}

.examDetail_popup_btn {
  color: white;
  padding: 0.6rem 1.5rem;
  font-size: larger;
  cursor: pointer;
  border-radius: 1rem;
  margin: 1rem 1rem;
}

@media screen and (max-width: 600px) {
  .examDetail {
    font-size: 1.6rem;
    margin: 5rem 1rem;
  }

  .examDetial_body {
    flex-direction: column;
  }

  .examDetail_body_right {
    margin-top: 1rem;
    margin-left: 0;
  }

  .examDetail_body_left_title {
    text-align: center;
  }

  .exam_detail_display_qr {
    height: 30vh;
    width: auto;
  }
}