.Link {
  text-decoration: none;
  color: black;
}

.Link:hover {
  text-decoration: none;
  color: black;
  background-color: white;
}

.homeExamCard {
  margin-top: 4rem;
  background-color: white;
  box-shadow: 3px 4px 18px -4px rgba(135, 135, 135, 0.63);
  -webkit-box-shadow: 3px 4px 18px -4px rgba(135, 135, 135, 0.63);
  -moz-box-shadow: 3px 4px 18px -4px rgba(135, 135, 135, 0.63);
  width: 32rem;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  margin-left: auto;
  margin-right: auto;
  height: 47rem;
}

.image_container {
  height: 20rem;
}
.homeExamCard_image {
  width: 100%;
  transition: transform 0.5s ease;
}

.image_container {
  overflow: hidden;
  border-radius: 20px;
}

.homeExamCard:hover .homeExamCard_image {
  transform: scale(1.3);
}

.homeExamCard_time {
  margin-top: 1rem;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.homeExamCard_time_icon {
  font-size: 1.8rem;
  margin-right: 0.6rem;
  color: var(--subtext-color) !important;
}

.homeExamCard_time_text {
  font-size: 1.4rem;
  color: var(--subtext-color);
}

.homeExamCard_title {
  font-size: 2rem;
  margin-top: 2rem;
  max-height: 7.5rem;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  padding: 0.2rem;
}

.homeExamCard_content {
  margin-top: 1rem;
  text-align: left;
  height: 8rem;
  padding: 0.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.homeExamCard_price {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
}

.homeExamCard_price_amount {
  text-decoration: none;
  font-weight: bold;
  color: var(--primary-color);
}

@media (max-width: 1074px) {
  .homeExamCard {
    width: 28rem;
    padding: 1rem;
    display: flex;
    justify-content: center;
    flex-direction: column;

    border-radius: 20px;
  }

  .homeExamCard_image {
    width: 26rem;
  }
}

@media (max-width: 639px) {
  .practiceSetComponent_mid_items {
    margin-right: 0rem;
  }

  .homeExamCard {
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: center;
    flex-direction: column;

    border-radius: 20px;
  }

  .homeExamCard_image {
    width: 100%;
  }
}

@media (max-width: 646px) {
  .homeExamCard {
    width: 90%;
  }

  .homeExamCard_title {
    font-size: 1.8rem;
    margin-top: 1.6rem;
  }

  .homeExamCard_content {
    font-size: 1.4rem;
  }
}

@media (max-width: 561px) {
  .homeExamCard_title {
    font-size: 1.6rem;
    margin-top: 1.6rem;
  }

  .homeExamCard_content {
    font-size: 1.3rem;
  }
}

@media (max-width: 607px) {
  .homeExamCard {
    width: 90%;
  }
}

@media (max-width: 463px) {
  .homeExamCard {
    width: 75%;
  }
}

@media (max-width: 400px) {
  .homeExamCard {
    width: 80%;
  }
}
